import {
  clearUserAccount,
  deleteCheckoutsForABN,
  deleteCustomer,
  deleteOrganisation,
  deletePartner,
  deleteRecommendationTool,
  deleteUser,
  getCustomer,
  getPartner,
  getUserByABN,
} from "api";
import { Auth } from "aws-amplify";
import { CertificationService } from "services/certification";
import { ROUTE } from "variables";

/**
 * Get current login session
 *
 * @returns current auth session
 */
const getCurrentAuthSession = () => {
  return Auth.currentAuthenticatedUser({ bypassCache: true });
};

/**
 * Get current auth user id and email
 *
 * @returns {id, email}
 */
const getCurrentAuthUserInfo = () => {
  return Auth.currentAuthenticatedUser({ bypassCache: true }).then(
    (session) => {
      return {
        id: session.username,
        email: session.attributes.email,
        abn: session.attributes["custom:abn_number"],
        purchase: session.attributes["custom:purchase"],
        group:
          session.signInUserSession?.accessToken?.payload["cognito:groups"] ||
          [],
      };
    }
  );
};

/**
 * Check if user is login or not
 * Set the state accordingly if provided
 * @param {fn} setLoggedIn
 */
const checkLoginState = () =>
  getCurrentAuthUserInfo()
    .then((sess) => {
      return sess;
    })
    .catch((error) => {
      return undefined;
    });

/**
 * Log out of current session
 * @param {fn} setLoggedIn
 */
const signOut = async (onSignout = () => {}) => {
  try {
    await Auth.signOut();
    onSignout();
  } catch (error) {
    console.error("Error signing out:", error);
  }
};

/**
 *
 * @param {string} email
 * @param {string} password
 * @param {fn} navigateCallback
 * @param {fn} onSigninCallback
 */
const signIn = async (
  email = "",
  password = "",
  navigateCallback = () => {},
  onSigninCallback = () => {},
  onChallengeCallback = () => {},
  onSetupMFACallback = () => {}
) => {
  try {
    if (!email || !password) {
      throw new Error();
    }
    const result = await Auth.signIn(email, password);
    // check challenge
    if (result.challengeName === "SOFTWARE_TOKEN_MFA") {
      onChallengeCallback(result);
    } else if (result.preferredMFA === "NOMFA") {
      console.log("no MFA setup");
      onSetupMFACallback(result);
    } else if (
      (
        !result?.signInUserSession?.accessToken?.payload["cognito:groups"] || []
      ).includes("admin")
    ) {
      await Auth.signOut();
      window.location.href = ROUTE.ROOT;
    } else {
      navigateCallback();
      onSigninCallback();
    }
  } catch (error) {
    console.error("Please provide valid email and password.");
  }
};

/**
 * Register new user information to User Pools
 *
 * @param {Object} input
 * @param {fn} confirmCallback
 * @param {fn} onSignupCallback
 */
const signUp = async (
  input = {},
  confirmCallback = () => {},
  onSignupCallback = () => {}
) => {
  let result;
  try {
    const { email, password, firstName, lastName, abn } = input;
    if (!email || !password || !firstName || !lastName || !abn) {
      throw new Error();
    }
    let stripePurchaseSession = sessionStorage.getItem(
      "STRIPE_PURCHASE_SESSION"
    );
    result = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        given_name: firstName,
        family_name: lastName,
        "custom:abn_number": abn,
        "custom:purchase": stripePurchaseSession,
      },
    });
  } catch (error) {
    if (error.code === "UsernameExistsException") {
      throw error;
    }
    console.error("Failed to register new account.");
    console.error(error);
  }
  if (!result) {
    throw new Error("register failed");
  }
  if (!result.userConfirmed) {
    confirmCallback();
  }
  onSignupCallback(result);
};

/**
 * Confirm user register information
 * with email confirmation code
 * to add user to Federated Identities
 *
 * @param {string} username
 * @param {string} code
 * @param {fn} navigateCallback
 * @param {fn} confirmCallback
 */
const confirmSignup = async (
  username,
  code,
  navigateCallback = () => {},
  confirmCallback = () => {}
) => {
  try {
    await Auth.confirmSignUp(username, code);
    navigateCallback();
    confirmCallback();
  } catch (error) {
    console.error("Failed to confirm register information.");
    throw error;
  }
};

/**
 * Resend confirmation code to user email
 *
 * @param {string} username
 */
const resendConfirmationCode = async (username) => {
  try {
    await Auth.resendSignUp(username);
  } catch (error) {
    console.error("Failed to resend code.");
  }
};

/**
 * Send confirmation code to user's email
 * for password reset
 *
 * @param {*} email
 * @param {*} callbackFn
 * @returns
 */
const forgotPassword = async (
  email,
  callbackFn = () => {},
  onError = () => {}
) => {
  return Auth.forgotPassword(email)
    .then((data) => {
      // console.log(data);
      callbackFn();
    })
    .catch((err) => {
      console.log("operation failed");
      onError(err);
      // console.error(err)
    });
};

const forgotPasswordSubmit = async (
  email,
  code,
  newpwd,
  callbackFn = () => {},
  onError = () => {}
) => {
  return Auth.forgotPasswordSubmit(email, code, newpwd)
    .then((data) => {
      // console.log(data);
      callbackFn();
    })
    .catch((err) => {
      console.log("operation failed");
      onError(err);
    });
};

const adminClearUser = async (user) => {
  const username = user?.owner;
  const ABN = user?.ABN;
  const userId = user?.id;

  let ok = "error";
  try {
    let users = await getUserByABN(ABN);

    if (users?.length === 1) {
      // if there is only one user left, delete org data
      // clear assessment tool
      await deleteRecommendationTool(ABN);

      // clear database
      await CertificationService.clearCertification(ABN);

      await deleteUser({
        id: userId,
      });

      await deleteOrganisation({
        ABN,
      });

      await deleteCustomer({ ABN });

      if (!!(await getPartner(ABN))) await deletePartner({ ABN });

      await deleteCheckoutsForABN(ABN);
    } else {
      // just delete user
      await deleteUser({
        id: userId,
      });
    }

    // clear cognito account
    await clearUserAccount(username);

    ok = "success";
  } catch (err) {
    console.log(err);
    ok = "error";
  }
  return ok;
};

const setupTOTP = async () => {
  const user = await getCurrentAuthSession();

  const code = await Auth.setupTOTP(user);
  console.log(code);

  return [user, code];
};

const verifyTOTP = async (challengeAnswer) => {
  const user = await getCurrentAuthSession();

  await Auth.verifyTotpToken(user, challengeAnswer)
    .then(async () => {
      await Auth.setPreferredMFA(user, "TOTP");
    })
    .catch((e) => {
      console.log("Not verified");
      console.log(e);
    });
};

const disableTOTP = async () => {
  const user = await getCurrentAuthSession();

  await Auth.setPreferredMFA(user, "NOMFA");
  console.log("disabled");
};

const verifyMFA = async (
  email,
  password,
  code,
  navigateCallback = () => {},
  onSigninCallback = () => {}
) => {
  try {
    const user = await Auth.signIn(email, password);
    const loggedUser = await Auth.confirmSignIn(user, code, user.challengeName);

    if (
      (
        !loggedUser?.signInUserSession?.accessToken?.payload[
          "cognito:groups"
        ] || []
      ).includes("admin")
    ) {
      await Auth.signOut();
      window.location.href = ROUTE.ROOT;
    } else {
      navigateCallback();
      onSigninCallback();
    }
  } catch (e) {
    console.log(e);
  }
};

export const AuthService = {
  adminClearUser,
  getCurrentAuthSession,
  getCurrentAuthUserInfo,
  checkLoginState,
  signOut,
  signIn,
  signUp,
  confirmSignup,
  resendConfirmationCode,
  forgotPassword,
  forgotPasswordSubmit,
  setupTOTP,
  verifyTOTP,
  disableTOTP,
  verifyMFA,
};
